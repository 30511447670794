import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import HomePage from "@src/pages/home-page/HomePage";
import AboutPage from "@src/pages/about-page/AboutPage";
import AllProductsPage from "@src/pages/products-page/AllProductsPage";
import ContactPage from "@src/pages/contact-page/ContactPage";
import MainLayout from "@src/layout/MainLayout";
import BearingsProductsPage from "@src/pages/products-page/BearingsProductsPage";
import AutomotiveProductsPage from "@src/pages/products-page/AutomotiveProductsPage";
import ScrollToTop from "@src/ScrollToTop";
// eslint-disable-next-line
import BearingProductsDetailPage from "@src/pages/product-details-page/BearingProductsDetailPage";
// eslint-disable-next-line
import AutomotiveProductsDetailPage from "@src/pages/product-details-page/AutomotiveProductsDetailPage";
// eslint-disable-next-line
import AllProductsPagesDetail from "@src/pages/product-details-page/AllProductsPagesDetail";

const App = () => {
  return (
    <BrowserRouter>
      <ScrollToTop>
        <Routes onUpdate={() => window.scrollTo(0, 0)}>
          <Route exact path="/" element={<MainLayout />}>
            <Route exact path="/" element={<HomePage />} />
            <Route path="about" element={<AboutPage />} />
            <Route path="contact" element={<ContactPage />} />
            <Route path="all_products" element={<AllProductsPage />} />
            <Route
              path="bearings_products"
              element={<BearingsProductsPage />}
            />
            <Route
              path="automotive_products"
              element={<AutomotiveProductsPage />}
            />
            {/* Sub products detail from all products */}
            {/* <Route
              path="/all_products/all_products_detail/:id"
              element={<AllProductsPagesDetail />}
            /> */}
            {/* Sub products detail from bearing product */}
            {/* <Route
              path="/bearings_products/bearings_detail/:id"
              element={<BearingProductsDetailPage />}
            /> */}
            {/* Sub products detail from bearing product */}
            {/* <Route
              path="/automotive_products/automotive_detail/:id"
              element={<AutomotiveProductsDetailPage />}
            /> */}
          </Route>
        </Routes>
      </ScrollToTop>
    </BrowserRouter>
  );
};

export default App;

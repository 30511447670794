import {
  Box,
  Flex,
  HStack,
  Image,
  SimpleGrid,
  Text,
  VStack,
} from "@chakra-ui/react";
import CurveLine5 from "@src/assets/curve-line5.svg";
import authorize from "@src/assets/authorization.png";
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import Fade from "react-reveal/Fade";
import { AllBrands } from "@src/data/BrandingsData";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";

const AboutPage = () => {
  // Slider Props
  const properties = {
    duration: 3000,
    slidesToShow: 1,
    slidesToScroll: 1,
    // autoplay: true,
    // infinite: true,
    // transitionDuration: 500,
    // indicators: true,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 6,
        },
      },
      {
        breakpoint: 960,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 520,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 320,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
    prevArrow: (
      <div style={{ width: "30px", cursor: "pointer" }}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512 512"
          fill="#C53030"
        >
          <path d="M242 180.6v-138L0 256l242 213.4V331.2h270V180.6z" />
        </svg>
      </div>
    ),
    nextArrow: (
      <div style={{ width: "30px", cursor: "pointer" }}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512 512"
          fill="#C53030"
        >
          <path d="M512 256L270 42.6v138.2H0v150.6h270v138z" />
        </svg>
      </div>
    ),
  };
  return (
    <>
      {/* About Page Heading */}
      <VStack
        mx="-3.5rem"
        h={{
          base: "100px",
          sm: "200px",
          md: "300px",
          lg: "300px",
          xl: "300px",
        }}
        backgroundImage={CurveLine5}
        backgroundRepeat="no-repeat"
      >
        <Text
          fontSize={{
            base: "lg",
            sm: "xl",
            md: "3xl",
            lg: "3xl",
            xl: "4xl",
          }}
          fontWeight="bold"
          mt={{
            base: "40px",
            sm: "80px",
            md: "130px",
            lg: "130px",
            xl: "130px",
          }}
        >
          About Us
        </Text>
      </VStack>

      {/* About Page Body */}
      <Box
        w="auto"
        mt={5}
        textColor="red.600"
        fontSize={{
          base: "md",
          sm: "md",
          md: "lg",
          lg: "lg",
          xl: "lg",
        }}
        fontWeight="bold"
      >
        <Fade bottom cascade>
          Company Profile
        </Fade>
      </Box>
      <HStack w="full" h="20px">
        <Box
          w={{
            base: "120px",
            sm: "120px",
            md: "135px",
            lg: "135px",
            xl: "135px",
          }}
          className="border-b-2 border-red-600"
        />
      </HStack>

      {/* About Page Body / Company Profile Part */}
      <Box fontSize="sm">
        <SimpleGrid columns={{ base: 1, sm: 1, md: 1, lg: 2, xl: 2 }} mb={7}>
          <Box>
            <Fade bottom cascade>
              <div className="space-y-7">
                <Text>
                  Our company was established after the government of union of
                  Myanmar allowed citizen of Myanmar to engage in international
                  trade under banner of free market oriented economic system in
                  early 1995.
                </Text>

                <Text>
                  We are one of the leading importers of all kinds of bearings.
                  We are also largest stock list of all kinds of ball bearings
                  with the full range to meet the customers demand in Myanmar
                  market.
                </Text>

                <Text>
                  Apart from our bearings business we are also engaged in world
                  renowned NTN bearing, we are keen to get dealership so that we
                  can distribute to all customers in whole of Myanmar territory.
                </Text>

                <Text>
                  Our network of customers is in whole country. We distribute to
                  each and every corner of Myanmar. “Customer’s satisfaction is
                  our main goal”.
                </Text>
              </div>
            </Fade>
          </Box>

          <Flex
            justify="center"
            pt={{ base: "20px", sm: "20px", md: "20px", lg: "0px", xl: "0px" }}
          >
            <Fade bottom cascade></Fade>
            <Zoom>
              <Image
                className="rounded-md shadow-md"
                src={authorize}
                width={{
                  base: "250",
                  sm: "250",
                  md: "300",
                  lg: "300",
                  xl: "300",
                }}
                height={{
                  base: "200",
                  sm: "200",
                  md: "250",
                  lg: "250",
                  xl: "250",
                }}
                objectFit="cover"
              />
            </Zoom>
          </Flex>
        </SimpleGrid>

        <Fade bottom cascade>
          <div className="space-y-7">
            <Text>
              As Myanmar is moving towards industrialization. Our focus is in
              the field of industrial bearings. We satisfy customers need
              according to their demand and specification. And various range of
              industrial bearings we can supply.
            </Text>
            <Text>
              Apart from bearings we are diversifying in the field of auto spare
              parts which has good scope in Myanmar market.
            </Text>
          </div>
        </Fade>
      </Box>

      {/*Company brand slider part */}
      <Box
        justifyContent="center"
        mt={10}
        mb={10}
        // bgColor="red.200"
        mx="-3.5rem"
        px="3.5rem"
        className="py-5 border-t-2 border-b-2 border-red-600"
      >
        <Slide {...properties} autoplay={true}>
          {AllBrands?.map((image, index) => (
            <Flex
              w="150px"
              mx="auto"
              justifyContent="center"
              // bgColor="red.200"
              h="full"
              align="center"
            >
              <Image
                boxSize="auto"
                objectFit="fixed"
                src={image.img}
                key={index}
              />
            </Flex>
          ))}
        </Slide>
      </Box>
    </>
  );
};

export default AboutPage;

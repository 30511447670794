import {
  Box,
  Flex,
  HStack,
  Image,
  SimpleGrid,
  Text,
  VStack,
} from "@chakra-ui/react";
import React from "react";
import CurveLine5 from "@src/assets/curve-line5.svg";
import Fade from "react-reveal/Fade";
import Rotate from "react-reveal/Rotate";
import Iframe from "react-iframe";
import { AllBrands } from "@src/data/BrandingsData";
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";

const ContactPage = () => {
  // Slider Props
  const sliderProperties = {
    duration: 3000,
    slidesToShow: 1,
    slidesToScroll: 1,
    // autoplay: true,
    // infinite: true,
    // transitionDuration: 500,
    // indicators: true,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 6,
        },
      },
      {
        breakpoint: 960,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 520,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 320,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
    prevArrow: (
      <div style={{ width: "30px", cursor: "pointer" }}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512 512"
          fill="#C53030"
        >
          <path d="M242 180.6v-138L0 256l242 213.4V331.2h270V180.6z" />
        </svg>
      </div>
    ),
    nextArrow: (
      <div style={{ width: "30px", cursor: "pointer" }}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512 512"
          fill="#C53030"
        >
          <path d="M512 256L270 42.6v138.2H0v150.6h270v138z" />
        </svg>
      </div>
    ),
  };
  return (
    <>
      {/* Contact Page Heading */}
      <VStack
        mx="-3.5rem"
        h={{
          base: "100px",
          sm: "200px",
          md: "300px",
          lg: "300px",
          xl: "300px",
        }}
        backgroundImage={CurveLine5}
        backgroundRepeat="no-repeat"
      >
        <Text
          fontSize={{
            base: "lg",
            sm: "xl",
            md: "3xl",
            lg: "3xl",
            xl: "4xl",
          }}
          fontWeight="bold"
          mt={{
            base: "40px",
            sm: "80px",
            md: "130px",
            lg: "130px",
            xl: "130px",
          }}
        >
          Contact Us
        </Text>
      </VStack>

      {/* Contact Page Body */}
      <SimpleGrid
        mt={5}
        mb={10}
        h="auto"
        mx="-3.5rem"
        px="3.5rem"
        columns={{ base: 1, sm: 1, md: 1, lg: 2, xl: 2 }}
        // bgColor="red.200"
      >
        {/* Contact Page Body/ Place */}
        <div>
          <Box
            w="auto"
            textColor="red.600"
            fontSize={{
              base: "md",
              sm: "md",
              md: "lg",
              lg: "lg",
              xl: "lg",
            }}
            fontWeight="bold"
          >
            <Fade bottom cascade>
              Know Our place!
            </Fade>
          </Box>
          <HStack w="full" h="20px">
            <Box
              w={{
                base: "120px",
                sm: "120px",
                md: "135px",
                lg: "135px",
                xl: "135px",
              }}
              className="border-b-2 border-red-600"
            />
          </HStack>
          <Box
            fontSize={{
              base: "xs",
              sm: "sm",
              md: "md",
              lg: "md",
              xl: "md",
            }}
            pt={3}
          >
            <Fade bottom cascade>
              <div className="space-y-6">
                <Flex>
                  <Text minW="100px" fontWeight="bold">
                    Head Office
                  </Text>
                  <Box className="space-y-2">
                    <Text>
                      Room No(A/66,67) ,Naung Yoe Street, Mayangone Tsp ,Yangon,
                      Myanmar
                    </Text>
                    <Text>
                      095147960, 09777776942, 09751599993, 09751599994
                    </Text>
                    <Text>01-686316, 01-686317, 01-683551</Text>
                  </Box>
                </Flex>

                <Flex>
                  <Text minW="100px" fontWeight="bold">
                    Branch 1
                  </Text>
                  <Box className="space-y-2">
                    <Text>
                      Blk (B/38/39), Room (10/11), Bayinaung Market (Front
                      Side), Mayangone Tsp, Yangon
                    </Text>
                    <Text>09765147960, 09765020198, 09795400563,</Text>
                    <Text>01-682587, 01-682584, 01-686313, 01-683377</Text>
                    <Text>
                      (Oil Seal Shop: 09768690586, 09777999193, 09777999195,
                      01-686658)
                    </Text>
                  </Box>
                </Flex>

                <Flex>
                  <Text minW="100px" fontWeight="bold">
                    Branch 2
                  </Text>
                  <Box className="space-y-2">
                    <Text>
                      Room 5 No 91 Pin Shwe Nyaung St, Tamwe Tsp, Yangon
                    </Text>
                    <Text>095118853, 09401367781, 09782120377, 01-554274</Text>
                  </Box>
                </Flex>

                <Flex>
                  <Text minW="100px" fontWeight="bold">
                    Branch 3
                  </Text>
                  <Box className="space-y-2">
                    <Text>
                      No (5/6), 25 Wards, Pyi Htaung Su Road, South Dagon Tsp,
                      Yangon
                    </Text>
                    {/* <Text>09975298485, 024022658, 024069208, 024069209</Text> */}
                  </Box>
                </Flex>

                <Flex>
                  <Text minW="100px" fontWeight="bold">
                    Branch 4
                  </Text>
                  <Box className="space-y-2">
                    <Text>
                      No 27*28, 81 street, Chanayethazan Tsp, Mandalay, Myanmar
                    </Text>
                    <Text>09975298485, 024022658, 024069208, 024069209</Text>
                  </Box>
                </Flex>
              </div>
            </Fade>
          </Box>
        </div>

        {/* Contact Page Body/ Place Img */}

        <Rotate top right>
          <Box
            h="full"
            mt={{
              base: "10px",
              sm: "10px",
              md: "10px",
              lg: "0px",
              xl: "0px",
            }}
          >
            <Iframe
              url="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3818.193944828547!2d96.10418261049676!3d16.866297865885056!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xf407dac8cde2d84d!2z4YCZ4YC84YCQ4YC64YCe4YCu4YCQ4YCs!5e0!3m2!1sen!2smm!4v1655839503025!5m2!1sen!2smm"
              width="100%"
              height="100%"
              display="initial"
              position="relative"
              loading="lazy"
            />
          </Box>
        </Rotate>
      </SimpleGrid>

      {/*Company brand slider part */}
      <Box
        justifyContent="center"
        mt={10}
        mb={10}
        // bgColor="red.200"
        mx="-3.5rem"
        px="3.5rem"
        className="py-5 border-t-2 border-b-2 border-red-600"
      >
        <Slide {...sliderProperties} autoplay={true}>
          {AllBrands?.map((image, index) => (
            <Flex
              w="150px"
              mx="auto"
              justifyContent="center"
              // bgColor="red.200"
              h="full"
              align="center"
            >
              <Image
                boxSize="auto"
                objectFit="fixed"
                src={image.img}
                key={index}
              />
            </Flex>
          ))}
        </Slide>
      </Box>
    </>
  );
};

export default ContactPage;

import React from "react";
import logo from "@src/assets/Logo.jpg";
import {
  Box,
  Flex,
  HStack,
  Image,
  Spacer,
  Text,
  useDisclosure,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from "@chakra-ui/react";
import { Link, NavLink } from "react-router-dom";

const Navbar = () => {
  const {
    isOpen: isOpenProduct,
    onOpen: onOpenProduct,
    onClose: onCloseProduct,
  } = useDisclosure();
  // const {
  //   isOpen: isOpenBrands,
  //   onOpen: onOpenBrands,
  //   onClose: onCloseBrands,
  // } = useDisclosure();

  return (
    <>
      {/* Navbar */}
      <HStack
        minW="625px"
        textAlign="center"
        textColor="red.600"
        fontSize={{ base: "sm", sm: "sm", md: "md", lg: "md", xl: "md" }}
        spacing="20px"
        cursor="pointer"
        display={{
          base: "none",
          sm: "none",
          md: "none",
          lg: "flex",
          xl: "flex",
        }}
        // bgColor="green.200"
      >
        <Link to="/">
          <Box
            minW="100px"
            className="border-b-2 border-white py-2 hover:border-red-600"
          >
            <Text>Home</Text>
          </Box>
        </Link>

        <Link to="about">
          <Box
            minW="100px"
            className="border-b-2 border-white py-2 hover:border-red-600"
          >
            About
          </Box>
        </Link>

        <Menu isOpen={isOpenProduct} closeOnSelect={true}>
          <MenuButton
            className="border-b-2 border-white py-2 hover:border-red-600"
            onMouseEnter={onOpenProduct}
            onMouseLeave={onCloseProduct}
          >
            <Box minW="100px">Products</Box>
          </MenuButton>

          <MenuList
            onMouseEnter={onOpenProduct}
            onMouseLeave={onCloseProduct}
            mt={-2}
          >
            <NavLink to="all_products" onClick={onCloseProduct}>
              <MenuItem _hover={{ bgColor: "red.600", textColor: "white" }}>
                All products
              </MenuItem>
            </NavLink>

            <NavLink to="bearings_products" onClick={onCloseProduct}>
              <MenuItem _hover={{ bgColor: "red.600", textColor: "white" }}>
                Types of Bearings
              </MenuItem>
            </NavLink>

            <NavLink to="automotive_products" onClick={onCloseProduct}>
              <MenuItem _hover={{ bgColor: "red.600", textColor: "white" }}>
                Types of Automotive Parts
              </MenuItem>
            </NavLink>
          </MenuList>
        </Menu>

        {/* <Menu isOpen={isOpenBrands} closeOnSelect={true}>
          <MenuButton
            className="border-b-2 border-white py-2 hover:border-red-600"
            onMouseEnter={onOpenBrands}
            onMouseLeave={onCloseBrands}
          >
            <Box minW="100px">Authorized Brands</Box>
          </MenuButton>

          <MenuList
            onMouseEnter={onOpenBrands}
            onMouseLeave={onCloseBrands}
            mt={-2}
          >
            <NavLink to="#" onClick={onCloseBrands}>
              <MenuItem _hover={{ bgColor: "red.600", textColor: "white" }}>
                All Brands
              </MenuItem>
            </NavLink>

            <NavLink to="#" onClick={onCloseBrands}>
              <MenuItem _hover={{ bgColor: "red.600", textColor: "white" }}>
                Japan
              </MenuItem>
            </NavLink>

            <NavLink to="#" onClick={onCloseBrands}>
              <MenuItem _hover={{ bgColor: "red.600", textColor: "white" }}>
                Taiwan
              </MenuItem>
            </NavLink>

            <NavLink to="#" onClick={onCloseBrands}>
              <MenuItem _hover={{ bgColor: "red.600", textColor: "white" }}>
                UAE
              </MenuItem>
            </NavLink>

            <NavLink to="#" onClick={onCloseBrands}>
              <MenuItem _hover={{ bgColor: "red.600", textColor: "white" }}>
                INDIA
              </MenuItem>
            </NavLink>

            <NavLink to="#" onClick={onCloseBrands}>
              <MenuItem _hover={{ bgColor: "red.600", textColor: "white" }}>
                China
              </MenuItem>
            </NavLink>
          </MenuList>
        </Menu> */}

        <Link to="/contact">
          <Box
            minW="100px"
            className="border-b-2 border-white py-2 hover:border-red-600"
          >
            Contact
          </Box>
        </Link>
      </HStack>

      <Spacer />

      {/* Logo at Navbar */}
      <Flex
        // bgColor="yellow.200"
        flexDirection={{
          base: "row-reverse",
          sm: "row-reverse",
          md: "row-reverse",
          lg: "column",
          xl: "column",
        }}
        textAlign="center"
        alignItems="center"
        w={{ base: "full", sm: "full", md: "full", lg: "auto", xl: "auto" }}
      >
        <Image
          boxSize={{
            base: "40px",
            sm: "40px",
            md: "50px",
            lg: "50px",
            xl: "50px",
          }}
          objectFit="cover"
          src={logo}
        />

        <Text
          textColor="red.600"
          fontSize={{ base: "xs", sm: "sm", md: "md", lg: "lg", xl: "lg" }}
          mx={{ base: "15px", sm: "15px", md: "15px", lg: "0px", xl: "0px" }}
          mt={{ base: "0px", sm: "0px", md: "0px", lg: "5px", xl: "5px" }}
        >
          MYAT THIDA TRADING ENTERPRISE Co., LTD
        </Text>
      </Flex>
    </>
  );
};

export default Navbar;

import { Box, Flex, HStack, Image, SimpleGrid } from "@chakra-ui/react";
import React from "react";
import Fade from "react-reveal/Fade";

import { BrandingData } from "@src/data/HomePageBrandingData";
import { BrandingData2 } from "@src/data/HomePageBrandingData";
import { BrandingData3 } from "@src/data/HomePageBrandingData";
import { AllBrands } from "@src/data/BrandingsData";

import { Zoom } from "react-slideshow-image";
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import { HomepageSlider } from "@src/data/HomePageBrandingData";

import img1 from "@src/assets/homepage-logo/logo1.jpg";
import img4 from "@src/assets/homepage-logo/logo4.jpg";
import img7 from "@src/assets/homepage-logo/logo7.jpg";

const HomePage = () => {
  const zoomInProperties = {
    indicators: true,
    scale: 1.4,
    prevArrow: (
      <div
        style={{
          width: "20px",
          cursor: "pointer",
          marginRight: "-20px",
          zIndex: 2,
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512 512"
          fill="#C53030"
        >
          <path d="M242 180.6v-138L0 256l242 213.4V331.2h270V180.6z" />
        </svg>
      </div>
    ),
    nextArrow: (
      <div
        style={{
          width: "20px",
          cursor: "pointer",
          marginLeft: "-20px",
          zIndex: 2,
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512 512"
          fill="#C53030"
        >
          <path d="M512 256L270 42.6v138.2H0v150.6h270v138z" />
        </svg>
      </div>
    ),
  };

  // Slider Props
  const sliderProperties = {
    duration: 3000,
    slidesToShow: 1,
    slidesToScroll: 1,
    // autoplay: true,
    // infinite: true,
    // transitionDuration: 500,
    // indicators: true,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 6,
        },
      },
      {
        breakpoint: 960,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 520,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 320,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
    prevArrow: (
      <div style={{ width: "30px", cursor: "pointer" }}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512 512"
          fill="#C53030"
        >
          <path d="M242 180.6v-138L0 256l242 213.4V331.2h270V180.6z" />
        </svg>
      </div>
    ),
    nextArrow: (
      <div style={{ width: "30px", cursor: "pointer" }}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512 512"
          fill="#C53030"
        >
          <path d="M512 256L270 42.6v138.2H0v150.6h270v138z" />
        </svg>
      </div>
    ),
  };
  return (
    <>
      {/* Home Page Heading Slider*/}
      <div>
        <Zoom {...zoomInProperties}>
          {HomepageSlider.map((image, index) => (
            <div key={index}>
              <Image
                h={{
                  base: "200px",
                  sm: "200px",
                  md: "400px",
                  lg: "500px",
                  xl: "500px",
                }}
                w="100%"
                zIndex={1}
                src={image.img}
                objectFit="cover"
              />
            </div>
          ))}
        </Zoom>
      </div>

      {/* All Products Heading At Home Page */}
      <Flex
        w="full"
        fontSize={{
          base: "sm",
          sm: "lg",
          md: "xl",
          lg: "xl",
          xl: "2xl",
        }}
        fontWeight="bold"
        justifyContent="center"
        mt={5}
        textAlign="center"
      >
        Your Truthful Business Partner
      </Flex>
      <HStack w="full" justifyContent="center" h="30px">
        <Box w="220px" className="border-b-2 border-red-600" />
      </HStack>

      {/* Branding Image At Home Page */}
      <Box my={10} className="space-y-8">
        <SimpleGrid
          // bgColor="red.200"
          columns={{ base: 1, sm: 3, md: 3, lg: 3, xl: 3 }}
          spacing="25px"
        >
          <Fade left>
            <Flex
              justifyContent="center"
              // bgColor="green.200"
              h="full"
              align="center"
            >
              <Box className="rounded-md transition transform  md:hover:-translate-y-2 lg:hover:-translate-y-3 xl:hover:-translate-y-3 motion-reduce:transition-none motion-reduce:hover:transform-none hover:shadow-xl hover:shadow-red-700">
                <Image
                  onClick={() =>
                    window.open("http://nachi-tool.jp/bearing/", "_blank")
                  }
                  cursor="pointer"
                  src={img1}
                  maxW={{
                    base: "120px",
                    sm: "120px",
                    md: "150px",
                    lg: "200px",
                    xl: "200px",
                  }}
                  maxH={{
                    base: "100px",
                    sm: "100px",
                    md: "130px",
                    lg: "180px",
                    xl: "180px",
                  }}
                  className="rounded-md"
                />
              </Box>
            </Flex>
            {BrandingData?.map((brand, index) => (
              <Flex
                key={index}
                justifyContent="center"
                // bgColor="green.200"
                h="full"
                align="center"
              >
                <Box className="rounded-md transition transform  md:hover:-translate-y-2 lg:hover:-translate-y-3 xl:hover:-translate-y-3 motion-reduce:transition-none motion-reduce:hover:transform-none hover:shadow-xl hover:shadow-red-700">
                  <Image
                    src={brand.img}
                    maxW={{
                      base: "120px",
                      sm: "120px",
                      md: "150px",
                      lg: "200px",
                      xl: "200px",
                    }}
                    maxH={{
                      base: "100px",
                      sm: "100px",
                      md: "130px",
                      lg: "180px",
                      xl: "180px",
                    }}
                    className="rounded-md"
                  />
                </Box>
              </Flex>
            ))}
          </Fade>
        </SimpleGrid>

        <SimpleGrid
          // bgColor="red.200"
          columns={{ base: 1, sm: 3, md: 3, lg: 3, xl: 3 }}
          spacing="25px"
        >
          <Fade right>
            <Flex
              justifyContent="center"
              // bgColor="green.200"
              h="full"
              align="center"
            >
              <Box className="rounded-md transition transform  md:hover:-translate-y-2 lg:hover:-translate-y-3 xl:hover:-translate-y-3 motion-reduce:transition-none motion-reduce:hover:transform-none hover:shadow-xl hover:shadow-red-700">
                <Image
                  onClick={() =>
                    window.open(
                      "https://nsk.incony.de/web/na/en_JP/PKW/applicationSearch.xhtml",
                      "_blank"
                    )
                  }
                  cursor="pointer"
                  src={img4}
                  maxW={{
                    base: "120px",
                    sm: "120px",
                    md: "150px",
                    lg: "200px",
                    xl: "200px",
                  }}
                  maxH={{
                    base: "100px",
                    sm: "100px",
                    md: "130px",
                    lg: "180px",
                    xl: "180px",
                  }}
                  className="rounded-md"
                />
              </Box>
            </Flex>
            {BrandingData2?.map((brand, index) => (
              <Flex
                key={index}
                justifyContent="center"
                //bgColor="red.200"
                h="full"
                align="center"
              >
                <Box className="rounded-md transition transform  md:hover:-translate-y-2 lg:hover:-translate-y-3 xl:hover:-translate-y-3 motion-reduce:transition-none motion-reduce:hover:transform-none hover:shadow-xl hover:shadow-red-700">
                  <Image
                    src={brand.img}
                    maxW={{
                      base: "120px",
                      sm: "120px",
                      md: "150px",
                      lg: "200px",
                      xl: "200px",
                    }}
                    maxH={{
                      base: "100px",
                      sm: "100px",
                      md: "130px",
                      lg: "180px",
                      xl: "180px",
                    }}
                    className="rounded-md"
                  />
                </Box>
              </Flex>
            ))}
          </Fade>
        </SimpleGrid>

        <SimpleGrid
          // //bgColor="red.200"
          columns={{ base: 1, sm: 3, md: 3, lg: 3, xl: 3 }}
          spacing="25px"
        >
          <Fade left>
            <Flex
              justifyContent="center"
              // bgColor="green.200"
              h="full"
              align="center"
            >
              <Box className="rounded-md transition transform  md:hover:-translate-y-2 lg:hover:-translate-y-3 xl:hover:-translate-y-3 motion-reduce:transition-none motion-reduce:hover:transform-none hover:shadow-xl hover:shadow-red-700">
                <Image
                  onClick={() =>
                    window.open(
                      "https://www.ntnglobal.com/en/products/catalog/en/2203/index.html",
                      "_blank"
                    )
                  }
                  cursor="pointer"
                  src={img7}
                  maxW={{
                    base: "120px",
                    sm: "120px",
                    md: "150px",
                    lg: "200px",
                    xl: "200px",
                  }}
                  maxH={{
                    base: "100px",
                    sm: "100px",
                    md: "130px",
                    lg: "180px",
                    xl: "180px",
                  }}
                  className="rounded-md"
                />
              </Box>
            </Flex>
            {BrandingData3?.map((brand, index) => (
              <Flex
                key={index}
                justifyContent="center"
                //bgColor="red.200"
                h="full"
                align="center"
              >
                <Box className="rounded-md transition transform  md:hover:-translate-y-2 lg:hover:-translate-y-3 xl:hover:-translate-y-3 motion-reduce:transition-none motion-reduce:hover:transform-none hover:shadow-xl hover:shadow-red-700">
                  <Image
                    src={brand.img}
                    maxW={{
                      base: "120px",
                      sm: "120px",
                      md: "150px",
                      lg: "200px",
                      xl: "200px",
                    }}
                    maxH={{
                      base: "100px",
                      sm: "100px",
                      md: "130px",
                      lg: "180px",
                      xl: "180px",
                    }}
                    className="rounded-md"
                  />
                </Box>
              </Flex>
            ))}
          </Fade>
        </SimpleGrid>
      </Box>

      {/*Company brand slider part */}
      <Box
        justifyContent="center"
        mt={10}
        mb={10}
        // bgColor="red.200"
        mx="-3.5rem"
        px="3.5rem"
        className="py-5 border-t-2 border-b-2 border-red-600"
      >
        <Slide {...sliderProperties} autoplay={true}>
          {AllBrands?.map((image, index) => (
            <Flex
              w="150px"
              mx="auto"
              justifyContent="center"
              // bgColor="red.200"
              h="full"
              align="center"
            >
              <Image
                boxSize="auto"
                objectFit="fixed"
                src={image.img}
                key={index}
              />
            </Flex>
          ))}
        </Slide>
      </Box>
    </>
  );
};

export default HomePage;

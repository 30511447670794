// Slider Images
import sliderimg1 from "@src/assets/homepage-slider/LubDG.jpg";
import sliderimg2 from "@src/assets/homepage-slider/DGBB2.jpg";
import sliderimg3 from "@src/assets/homepage-slider/DGBB3.jpg";
import sliderimg4 from "@src/assets/homepage-slider/NICA.jpg";

// Branding Images
import img2 from "@src/assets/homepage-logo/logo2.jpg";
import img3 from "@src/assets/homepage-logo/logo3.jpg";
import img5 from "@src/assets/homepage-logo/logo5.jpg";
import img6 from "@src/assets/homepage-logo/logo6.jpg";
import img8 from "@src/assets/homepage-logo/logo8.jpg";
import img9 from "@src/assets/homepage-logo/logo9.jpg";

export const HomepageSlider = [
  {
    img: sliderimg1,
  },
  {
    img: sliderimg2,
  },
  {
    img: sliderimg3,
  },
  {
    img: sliderimg4,
  },
];

export const BrandingData = [
  {
    img: img2,
  },
  {
    img: img3,
  },
];

export const BrandingData2 = [
  {
    img: img5,
  },
  {
    img: img6,
  },
];

export const BrandingData3 = [
  {
    img: img8,
  },
  {
    img: img9,
  },
];

import {
  Box,
  Flex,
  HStack,
  Image,
  SimpleGrid,
  Text,
  VStack,
} from "@chakra-ui/react";
import React from "react";
import CurveLine5 from "@src/assets/curve-line5.svg";
import Fade from "react-reveal/Fade";
import { AutomotiveProducts } from "@src/data/ProductsData";
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import { AutomotiveBrands } from "@src/data/BrandingsData";

const AutomotiveProductsPage = () => {
  // Slider Props
  const properties = {
    duration: 3000,
    slidesToShow: 1,
    slidesToScroll: 1,
    // autoplay: true,
    // infinite: true,
    // transitionDuration: 500,
    // indicators: true,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 6,
        },
      },
      {
        breakpoint: 960,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 520,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 320,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
    prevArrow: (
      <div style={{ width: "30px", cursor: "pointer" }}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512 512"
          fill="#C53030"
        >
          <path d="M242 180.6v-138L0 256l242 213.4V331.2h270V180.6z" />
        </svg>
      </div>
    ),
    nextArrow: (
      <div style={{ width: "30px", cursor: "pointer" }}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512 512"
          fill="#C53030"
        >
          <path d="M512 256L270 42.6v138.2H0v150.6h270v138z" />
        </svg>
      </div>
    ),
  };
  return (
    <>
      {/* Products Page Heading */}
      <VStack
        mx="-3.5rem"
        h={{
          base: "100px",
          sm: "200px",
          md: "300px",
          lg: "300px",
          xl: "300px",
        }}
        backgroundImage={CurveLine5}
        backgroundRepeat="no-repeat"
      >
        <Text
          fontSize={{
            base: "lg",
            sm: "xl",
            md: "3xl",
            lg: "3xl",
            xl: "4xl",
          }}
          fontWeight="bold"
          mt={{
            base: "40px",
            sm: "80px",
            md: "130px",
            lg: "130px",
            xl: "130px",
          }}
        >
          Products
        </Text>
      </VStack>

      {/* All Products Heading At Home Page */}
      <Flex
        w="full"
        fontSize={{
          base: "sm",
          sm: "lg",
          md: "xl",
          lg: "xl",
          xl: "2xl",
        }}
        fontWeight="bold"
        justifyContent="center"
        mt={5}
        textAlign="center"
      >
        Types Of Automotive Parts
      </Flex>
      <HStack w="full" justifyContent="center" h="30px">
        <Box w="200px" className="border-b-2 border-red-600" />
      </HStack>

      <Box my={10}>
        <SimpleGrid
          columns={{ base: 1, sm: 3, md: 3, lg: 3, xl: 3 }}
          spacingX="25px"
          spacingY="60px"
        >
          <Fade bottom cascade>
            {AutomotiveProducts?.map((product, index) => (
              // <Link
              //   key={product.id}
              //   to={`/automotive_products/automotive_detail/${product.id}`}
              // >
              <Flex justifyContent="center" key={index}>
                <Box
                  className="space-y-5 rounded-lg hover:shadow-2xl transition transform hover:bg-red-700 hover:text-white"
                  textAlign="center"
                >
                  <Image
                    className="rounded-t-lg"
                    src={product?.img}
                    w={{
                      base: "170px",
                      sm: "170px",
                      md: " 200px",
                      lg: " 250px",
                      xl: " 250px",
                    }}
                    h={{
                      base: "100px",
                      sm: "90px",
                      md: "130px",
                      lg: "180px",
                      xl: "180px",
                    }}
                  />
                  <Text
                    fontWeight="bold"
                    pb={5}
                    fontSize={{
                      base: "xs",
                      sm: "sm",
                      md: "md",
                      lg: "lg",
                      xl: "lg",
                    }}
                  >
                    {product?.name}
                  </Text>
                </Box>
              </Flex>
              // </Link>
            ))}
          </Fade>
        </SimpleGrid>
      </Box>

      {/* Products slider part */}
      <Box
        justifyContent="center"
        mt={10}
        mb={10}
        // bgColor="red.200"
        mx="-3.5rem"
        px="3.5rem"
        className="py-5 border-t-2 border-b-2 border-red-600"
      >
        <Slide {...properties} autoplay={true}>
          {AutomotiveBrands?.map((image, index) => (
            <Flex
              w="150px"
              mx="auto"
              justifyContent="center"
              // bgColor="red.200"
              h="full"
              align="center"
            >
              <Image
                boxSize="auto"
                objectFit="fixed"
                src={image.img}
                key={index}
              />
            </Flex>
          ))}
        </Slide>
      </Box>
    </>
  );
};

export default AutomotiveProductsPage;

// Bearing Products
// import bearingsimg1 from "@src/assets/products-img/bearings/img1.png";
import bearingsimg2 from "@src/assets/products-img/bearings/img2.jpg";
// import bearingsimg3 from "@src/assets/products-img/bearings/img3.jpg";
import bearingsimg4 from "@src/assets/products-img/bearings/img4.jpg";
import bearingsimg5 from "@src/assets/products-img/bearings/img5.jpg";
import bearingsimg6 from "@src/assets/products-img/bearings/img6.jpg";
import bearingsimg7 from "@src/assets/products-img/bearings/img7.jpg";
import bearingsimg8 from "@src/assets/products-img/bearings/img8.png";

// Automotive Products
import automotiveimg1 from "@src/assets/products-img/automotive/img1.jpg";
import automotiveimg2 from "@src/assets/products-img/automotive/img2.png";
import automotiveimg3 from "@src/assets/products-img/automotive/img3.jpg";
import automotiveimg4 from "@src/assets/products-img/automotive/img4.jpg";
import automotiveimg5 from "@src/assets/products-img/automotive/img5.png";
import automotiveimg6 from "@src/assets/products-img/automotive/img6.png";
import automotiveimg7 from "@src/assets/products-img/automotive/img7.jpg";
import automotiveimg8 from "@src/assets/products-img/automotive/img8.jpg";
import automotiveimg9 from "@src/assets/products-img/automotive/img9.png";

export const BearingsProducts = [
  {
    id: 1,
    img: bearingsimg2,
    name: "Industrial Bearings",
    detail: [
      {
        id: 1,
        img: bearingsimg2,
        name: "Industrial Bearings",
      },
      {
        id: 2,
        img: bearingsimg4,
        name: "Automotive Bearing",
      },
      {
        id: 3,
        img: bearingsimg4,
        name: "Automotive Bearing",
      },
    ],
  },
  {
    id: 2,
    img: bearingsimg4,
    name: "Automotive Bearing",
    detail: [
      {
        id: 1,
        img: bearingsimg5,
        name: "Pillow Block",
      },
      {
        id: 2,
        img: bearingsimg6,
        name: "Water Pump Bearing",
      },
    ],
  },
  {
    id: 3,
    img: bearingsimg5,
    name: "Pillow Block",
  },
  {
    id: 4,
    img: bearingsimg6,
    name: "Water Pump Bearing",
  },
  {
    id: 5,
    img: bearingsimg7,
    name: "Universal Joint Ball",
  },
  {
    id: 6,
    img: bearingsimg8,
    name: "Kingpin Kit",
  },
];

export const AutomotiveProducts = [
  {
    id: 1,
    img: automotiveimg1,
    name: "Oil Seal & O-ring",
  },
  {
    id: 2,
    img: automotiveimg2,
    name: "Oil Seal & O-ring",
  },
  {
    id: 3,
    img: automotiveimg3,
    name: "Oil Seal & O-ring",
  },
  {
    id: 4,
    img: automotiveimg4,
    name: "CV Joint",
  },
  {
    id: 5,
    img: automotiveimg5,
    name: "Steering Rack",
  },
  {
    id: 6,
    img: automotiveimg6,
    name: "Shock Absorber",
  },
  {
    id: 7,
    img: automotiveimg7,
    name: "Hub-Ball Bearing",
  },
  {
    id: 8,
    img: automotiveimg8,
    name: "Water Pump",
  },
  {
    id: 9,
    img: automotiveimg9,
    name: "Spark Plug",
  },
];

export const AllProducts = [
  {
    id: 1,
    img: bearingsimg2,
    name: "Industrial Bearings",
    detail: [
      {
        id: 1,
        img: bearingsimg2,
        name: "Industrial Bearings",
      },
      {
        id: 2,
        img: bearingsimg4,
        name: "Automotive Bearing",
      },
      {
        id: 3,
        img: bearingsimg4,
        name: "Automotive Bearing",
      },
    ],
  },
  {
    id: 2,
    img: bearingsimg4,
    name: "Automotive Bearing",
    detail: [
      {
        id: 1,
        img: bearingsimg5,
        name: "Pillow Block",
      },
      {
        id: 2,
        img: bearingsimg6,
        name: "Water Pump Bearing",
      },
    ],
  },
  {
    id: 3,
    img: bearingsimg5,
    name: "Pillow Block",
  },
  {
    id: 4,
    img: bearingsimg6,
    name: "Water Pump Bearing",
  },
  {
    id: 5,
    img: bearingsimg7,
    name: "Universal Joint Ball",
  },
  {
    id: 6,
    img: bearingsimg8,
    name: "Kingpin Kit",
  },
  {
    id: 7,
    img: automotiveimg1,
    name: "Oil Seal & O-ring",
  },
  {
    id: 8,
    img: automotiveimg2,
    name: "Oil Seal & O-ring",
  },
  {
    id: 9,
    img: automotiveimg3,
    name: "Oil Seal & O-ring",
  },
  {
    id: 10,
    img: automotiveimg4,
    name: "CV Joint",
  },
  {
    id: 11,
    img: automotiveimg5,
    name: "Steering Rack",
  },
  {
    id: 12,
    img: automotiveimg6,
    name: "Shock Absorber",
  },
  {
    id: 13,
    img: automotiveimg7,
    name: "Hub-Ball Bearing",
  },
  {
    id: 14,
    img: automotiveimg8,
    name: "Water Pump",
  },
  {
    id: 15,
    img: automotiveimg9,
    name: "Spark Plug",
  },
];

// Branding images at About Page
import image1 from "@src/assets/brands-logo/img1.jpg";
import image5 from "@src/assets/brands-logo/img5.jpg";
import image9 from "@src/assets/brands-logo/img9.jpg";
import image19 from "@src/assets/brands-logo/img19.png";
import image20 from "@src/assets/brands-logo/img20.jpg";
import image21 from "@src/assets/brands-logo/img21.jpg";
import image23 from "@src/assets/brands-logo/img23.jpg";

// Automotive branding logo
import automotive1 from "@src/assets/products-brands-logo/automotive/automotive1.PNG";
import automotive2 from "@src/assets/products-brands-logo/automotive/automotive2.PNG";
import automotive3 from "@src/assets/products-brands-logo/automotive/automotive3.PNG";
import automotive4 from "@src/assets/products-brands-logo/automotive/automotive4.PNG";
import automotive5 from "@src/assets/products-brands-logo/automotive/automotive5.PNG";
import automotive6 from "@src/assets/products-brands-logo/automotive/automotive6.PNG";
import automotive7 from "@src/assets/products-brands-logo/automotive/automotive7.PNG";
import automotive8 from "@src/assets/products-brands-logo/automotive/automotive8.PNG";
import automotive9 from "@src/assets/products-brands-logo/automotive/automotive9.PNG";
import automotive10 from "@src/assets/products-brands-logo/automotive/automotive10.PNG";
import automotive11 from "@src/assets/products-brands-logo/automotive/automotive11.PNG";
import automotive12 from "@src/assets/products-brands-logo/automotive/automotive12.PNG";
import automotive13 from "@src/assets/products-brands-logo/automotive/automotive13.PNG";
import automotive14 from "@src/assets/products-brands-logo/automotive/automotive14.PNG";

// Bearings branding logo
import bearing1 from "@src/assets/products-brands-logo/bearings/bearing1.jpg";
import bearing2 from "@src/assets/products-brands-logo/bearings/bearing2.jpg";
import bearing3 from "@src/assets/products-brands-logo/bearings/bearing3.jpg";
import bearing4 from "@src/assets/products-brands-logo/bearings/bearing4.jpg";
import bearing5 from "@src/assets/products-brands-logo/bearings/bearing5.jpg";
import bearing6 from "@src/assets/products-brands-logo/bearings/bearing6.jpg";
import bearing7 from "@src/assets/products-brands-logo/bearings/bearing7.jpg";
import bearing8 from "@src/assets/products-brands-logo/bearings/bearing8.jpg";
import bearing9 from "@src/assets/products-brands-logo/bearings/bearing9.jpg";

export const AllBrands = [
  { img: image1 },
  { img: image5 },
  { img: image9 },
  { img: image19 },
  { img: image20 },
  { img: image21 },
  { img: image23 },
  { img: bearing1 },
  { img: bearing2 },
  { img: bearing3 },
  { img: bearing4 },
  { img: bearing5 },
  { img: bearing6 },
  { img: bearing7 },
  { img: bearing8 },
  { img: bearing9 },
  { img: automotive1 },
  { img: automotive2 },
  { img: automotive3 },
  { img: automotive4 },
  { img: automotive5 },
  { img: automotive6 },
  { img: automotive7 },
  { img: automotive8 },
  { img: automotive9 },
  { img: automotive10 },
  { img: automotive11 },
  { img: automotive12 },
  { img: automotive13 },
  { img: automotive14 },
];

export const AutomotiveBrands = [
  { img: automotive1 },
  { img: automotive2 },
  { img: automotive3 },
  { img: automotive4 },
  { img: automotive5 },
  { img: automotive6 },
  { img: automotive7 },
  { img: automotive8 },
  { img: automotive9 },
  { img: automotive10 },
  { img: automotive11 },
  { img: automotive12 },
  { img: automotive13 },
  { img: automotive14 },
];

export const BearingsBrands = [
  { img: bearing1 },
  { img: bearing2 },
  { img: bearing3 },
  { img: bearing4 },
  { img: bearing5 },
  { img: bearing6 },
  { img: bearing7 },
  { img: bearing8 },
  { img: bearing9 },
];

export const AllProductsBrands = [
  { img: bearing1 },
  { img: bearing2 },
  { img: bearing3 },
  { img: bearing4 },
  { img: bearing5 },
  { img: bearing6 },
  { img: bearing7 },
  { img: bearing8 },
  { img: bearing9 },
  { img: automotive1 },
  { img: automotive2 },
  { img: automotive3 },
  { img: automotive4 },
  { img: automotive5 },
  { img: automotive6 },
  { img: automotive7 },
  { img: automotive8 },
  { img: automotive9 },
  { img: automotive10 },
  { img: automotive11 },
  { img: automotive12 },
  { img: automotive13 },
  { img: automotive14 },
];

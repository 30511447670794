import React, { useState } from "react";
import { Link, Outlet } from "react-router-dom";
import { RiMenu3Line, RiCloseLine } from "react-icons/ri";
import logo2 from "@src/assets/logo2.png";
import { FaHome } from "react-icons/fa";
import { FaBuilding } from "react-icons/fa";
import { MdContacts } from "react-icons/md";
import { FaListUl } from "react-icons/fa";
import { FaCircle } from "react-icons/fa";
import { RiArrowDropUpLine } from "react-icons/ri";
import { RiArrowDropDownLine } from "react-icons/ri";
// import { MdFactCheck } from "react-icons/md";

import {
  Box,
  Flex,
  HStack,
  Icon,
  Image,
  Spacer,
  Text,
  useDisclosure,
  Drawer,
  DrawerBody,
  DrawerContent,
  useMediaQuery,
  DrawerOverlay,
  Collapse,
} from "@chakra-ui/react";
import Footer from "@src/layout/Footer";
import Navbar from "@src/layout/Navbar";
import { useScrollPosition } from "@src/hooks/useScrollPosition";

const MainLayout = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  //   Mobile responsive for sidebar
  const [isMobile] = useMediaQuery("(max-width: 480px)");

  const [size, setSize] = useState("");

  // Drop Down State for products
  const [showProductDropdown, setShowProductDropdown] = useState(false);
  const handleProductDropdown = () =>
    setShowProductDropdown(!showProductDropdown);

  // Drop Down State for brands
  // const [showBrandsDropdown, setShowBrandsDropdown] = useState(false);
  // const handleBrandsDropdown = () => setShowBrandsDropdown(!showBrandsDropdown);

  // Handle State
  const handleOpen = (newSize) => {
    setSize(newSize);
    // console.log("size is", newSize);
    onOpen();
  };

  const handleClose = () => {
    if (showProductDropdown === true) {
      setShowProductDropdown(false);
      onClose();
    } else {
      onClose();
    }
  };

  // Sticky Scroll Shadow
  const scrollPosition = useScrollPosition();
  // console.log("scrollPosition is", scrollPosition);

  return (
    <div className="scroll-smooth">
      {/* Navbar & Sidebar */}
      <Flex
        py={3}
        userSelect="none"
        h="auto"
        alignItems="center"
        // mx="3.5rem"
        px="3.5rem"
        zIndex={5}
        className={`transition-shadow bg-white sticky top-0 ${
          scrollPosition > 0 ? "shadow-lg" : "shadow-none"
        }`}
      >
        {/* Sidebar when screen size is small */}
        <Icon
          w="25px"
          h="25px"
          color="red.600"
          as={RiMenu3Line}
          display={{
            base: "flex",
            sm: "flex",
            md: "flex",
            lg: "none",
            xl: "none",
          }}
          // onClick={() => handleOpen("xs")}
          onClick={() => {
            isMobile ? handleOpen("full") : handleOpen("xs");
          }}
          cursor="pointer"
        />

        {/* Navbar */}
        <Navbar useSelect="text" />
      </Flex>

      {/* SideBar Drawer */}
      <Drawer
        size={size}
        isOpen={isOpen}
        onClose={handleClose}
        placement="left"
        autoFocus={false}
      >
        <DrawerOverlay />
        <DrawerContent
          display={{
            base: "flex",
            sm: "flex",
            md: "flex",
            lg: "none",
            xl: "none",
          }}
        >
          {/* Drawer Header */}
          <HStack pointer="cursor" p={3} className="border-b-2 border-red-700">
            <Flex alignItems="center">
              <Image boxSize="30px" src={logo2} />
              <Text
                display={{
                  base: "block",
                  sm: "none",
                  md: "none",
                  lg: "none",
                  xl: "none",
                }}
                pl="10px"
                textColor="red.600"
                fontSize="xs"
              >
                MYAT THIDA TRADING ENTERPRISE Co., LTD
              </Text>
            </Flex>

            <Spacer />

            <Box
              w="30px"
              h="30px"
              px="3px"
              py="1px"
              borderRadius="50%"
              className="hover:bg-gray-100"
              cursor="pointer"
              onClick={onClose}
            >
              <Icon w="25px" h="25px" color="red.600" as={RiCloseLine} />
            </Box>
          </HStack>

          {/* Drawer Body */}
          <DrawerBody>
            <Link to="/" onClick={handleClose}>
              <Flex
                justifyContent={{
                  base: "center",
                  sm: "start",
                  md: "start",
                  lg: "start",
                  xl: "start",
                }}
                className="w-full text-red-700 hover:bg-red-700 hover:text-white block my-2 py-3 px-5 rounded-lg "
              >
                <Icon as={FaHome} my={1} />

                <Text ml={3}>Home</Text>
              </Flex>
            </Link>
            <Link to="about" onClick={handleClose}>
              <Flex
                justifyContent={{
                  base: "center",
                  sm: "start",
                  md: "start",
                  lg: "start",
                  xl: "start",
                }}
                className="w-full text-red-700 hover:bg-red-700 hover:text-white block my-2 py-3 px-5 rounded-lg "
              >
                <Icon as={FaBuilding} my={1} />

                <Text ml={3}>About</Text>
              </Flex>
            </Link>

            {/* Product Dropdown parts */}
            <Flex
              cursor="pointer"
              justifyContent={{
                base: "center",
                sm: "start",
                md: "start",
                lg: "start",
                xl: "start",
              }}
              className="w-full text-red-700 hover:bg-red-700 hover:text-white block my-2 py-3 px-5 rounded-lg "
              onClick={handleProductDropdown}
            >
              <Icon as={FaListUl} my={1} />
              <Text ml={3}>Products</Text>
              <Spacer
                display={{
                  base: "none",
                  sm: "flex",
                  md: "flex",
                  lg: "flex",
                  xl: "flex",
                }}
              />
              <Icon
                w="25px"
                h="25px"
                as={
                  showProductDropdown === false
                    ? RiArrowDropDownLine
                    : RiArrowDropUpLine
                }
              />
            </Flex>
            <Collapse animateOpacity in={showProductDropdown}>
              <Link to="all_products" onClick={onClose}>
                <Flex
                  justifyContent={{
                    base: "center",
                    sm: "start",
                    md: "start",
                    lg: "start",
                    xl: "start",
                  }}
                  className="w-full text-red-700 hover:bg-red-700 hover:text-white block my-2 py-3 px-5 rounded-lg "
                  onClick={handleProductDropdown}
                >
                  <Icon w="10px" h="10px" mt="7px" as={FaCircle} />

                  <Text ml={3}>All Products</Text>
                </Flex>
              </Link>
              <Link to="bearings_products" onClick={onClose}>
                <Flex
                  justifyContent={{
                    base: "center",
                    sm: "start",
                    md: "start",
                    lg: "start",
                    xl: "start",
                  }}
                  className="w-full text-red-700 hover:bg-red-700 hover:text-white block my-2 py-3 px-5 rounded-lg "
                  onClick={handleProductDropdown}
                >
                  <Icon w="10px" h="10px" mt="7px" as={FaCircle} />

                  <Text ml={3}>Types of Bearings</Text>
                </Flex>
              </Link>
              <Link to="automotive_products" onClick={onClose}>
                <Flex
                  justifyContent={{
                    base: "center",
                    sm: "start",
                    md: "start",
                    lg: "start",
                    xl: "start",
                  }}
                  className="w-full text-red-700 hover:bg-red-700 hover:text-white block my-2 py-3 px-5 rounded-lg "
                  onClick={handleProductDropdown}
                >
                  <Icon w="10px" h="10px" mt="7px" as={FaCircle} />

                  <Text ml={3}>Types of Automotive Parts</Text>
                </Flex>
              </Link>
            </Collapse>

            {/* Brands Dropdown parts */}
            {/* <Flex
              cursor="pointer"
              justifyContent={{
                base: "center",
                sm: "start",
                md: "start",
                lg: "start",
                xl: "start",
              }}
              className="w-full text-red-700 hover:bg-red-700 hover:text-white block my-2 py-3 px-5 rounded-lg "
              onClick={handleBrandsDropdown}
            >
              <Icon as={MdFactCheck} w="20px" h="20px" />
              <Text ml={3}>Authorized Brands</Text>
              <Spacer
                display={{
                  base: "none",
                  sm: "flex",
                  md: "flex",
                  lg: "flex",
                  xl: "flex",
                }}
              />
              <Icon
                w="25px"
                h="25px"
                as={
                  showBrandsDropdown === false
                    ? RiArrowDropDownLine
                    : RiArrowDropUpLine
                }
              />
            </Flex>
            <Collapse animateOpacity in={showBrandsDropdown}>
              <Link to="#" onClick={onClose}>
                <Flex
                  justifyContent={{
                    base: "center",
                    sm: "start",
                    md: "start",
                    lg: "start",
                    xl: "start",
                  }}
                  className="w-full text-red-700 hover:bg-red-700 hover:text-white block my-2 py-3 px-5 rounded-lg "
                  onClick={handleBrandsDropdown}
                >
                  <Icon w="10px" h="10px" mt="7px" as={FaCircle} />

                  <Text ml={3}>All Brands</Text>
                </Flex>
              </Link>
              <Link to="#" onClick={onClose}>
                <Flex
                  justifyContent={{
                    base: "center",
                    sm: "start",
                    md: "start",
                    lg: "start",
                    xl: "start",
                  }}
                  className="w-full text-red-700 hover:bg-red-700 hover:text-white block my-2 py-3 px-5 rounded-lg "
                  onClick={handleBrandsDropdown}
                >
                  <Icon w="10px" h="10px" mt="7px" as={FaCircle} />

                  <Text ml={3}>Japan</Text>
                </Flex>
              </Link>
              <Link to="#" onClick={onClose}>
                <Flex
                  justifyContent={{
                    base: "center",
                    sm: "start",
                    md: "start",
                    lg: "start",
                    xl: "start",
                  }}
                  className="w-full text-red-700 hover:bg-red-700 hover:text-white block my-2 py-3 px-5 rounded-lg "
                  onClick={handleBrandsDropdown}
                >
                  <Icon w="10px" h="10px" mt="7px" as={FaCircle} />

                  <Text ml={3}>Taiwan</Text>
                </Flex>
              </Link>
              <Link to="#" onClick={onClose}>
                <Flex
                  justifyContent={{
                    base: "center",
                    sm: "start",
                    md: "start",
                    lg: "start",
                    xl: "start",
                  }}
                  className="w-full text-red-700 hover:bg-red-700 hover:text-white block my-2 py-3 px-5 rounded-lg "
                  onClick={handleBrandsDropdown}
                >
                  <Icon w="10px" h="10px" mt="7px" as={FaCircle} />

                  <Text ml={3}>UAE</Text>
                </Flex>
              </Link>
              <Link to="#" onClick={onClose}>
                <Flex
                  justifyContent={{
                    base: "center",
                    sm: "start",
                    md: "start",
                    lg: "start",
                    xl: "start",
                  }}
                  className="w-full text-red-700 hover:bg-red-700 hover:text-white block my-2 py-3 px-5 rounded-lg "
                  onClick={handleBrandsDropdown}
                >
                  <Icon w="10px" h="10px" mt="7px" as={FaCircle} />

                  <Text ml={3}>INDIA</Text>
                </Flex>
              </Link>
              <Link to="#" onClick={onClose}>
                <Flex
                  justifyContent={{
                    base: "center",
                    sm: "start",
                    md: "start",
                    lg: "start",
                    xl: "start",
                  }}
                  className="w-full text-red-700 hover:bg-red-700 hover:text-white block my-2 py-3 px-5 rounded-lg "
                  onClick={handleBrandsDropdown}
                >
                  <Icon w="10px" h="10px" mt="7px" as={FaCircle} />

                  <Text ml={3}>China</Text>
                </Flex>
              </Link>
            </Collapse> */}

            <Link to="contact" onClick={handleClose}>
              <Flex
                justifyContent={{
                  base: "center",
                  sm: "start",
                  md: "start",
                  lg: "start",
                  xl: "start",
                }}
                className="w-full text-red-700 hover:bg-red-700 hover:text-white block my-2 py-3 px-5 rounded-lg "
              >
                <Icon as={MdContacts} my={1} />

                <Text ml={3}>Contact</Text>
              </Flex>
            </Link>
          </DrawerBody>
        </DrawerContent>
      </Drawer>

      {/* Others page under Navbar & Sidebar */}
      <section className="mx-14">
        <Outlet />
      </section>

      {/* Footer */}
      <Footer />
    </div>
  );
};

export default MainLayout;

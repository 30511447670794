import {
  Box,
  Flex,
  Icon,
  Image,
  SimpleGrid,
  Spacer,
  Text,
} from "@chakra-ui/react";
import React from "react";
import CurveLine6 from "@src/assets/curve-line6.svg";
import logo2 from "@src/assets/logo2.png";
import { BsFillTelephoneFill } from "react-icons/bs";
import { MdEmail } from "react-icons/md";
import { FaFacebook } from "react-icons/fa";
import { RiGlobalFill } from "react-icons/ri";
import { AiOutlineCopyrightCircle } from "react-icons/ai";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <>
      <Flex
        flexDirection="column"
        py={{ base: "1rem", sm: "1rem", md: "1rem", lg: "2rem", xl: "2rem" }}
        px="3.5rem"
        minH="300px"
        backgroundImage={CurveLine6}
        backgroundRepeat="no-repeat"
      >
        <SimpleGrid columns={{ base: 1, sm: 1, md: 1, lg: 2, xl: 2 }}>
          <Box>
            <Flex
              align="center"
              w={{
                base: "full",
                sm: "full",
                md: "full",
                lg: "auto",
                xl: "auto",
              }}
            >
              <Image
                boxSize={{
                  base: "40px",
                  sm: "40px",
                  md: "50px",
                  lg: "50px",
                  xl: "50px",
                }}
                src={logo2}
              />

              <Text
                px="10px"
                textColor="red.600"
                fontSize={{
                  base: "xs",
                  sm: "sm",
                  md: "md",
                  lg: "lg",
                  xl: "lg",
                }}
              >
                MYAT THIDA TRADING ENTERPRISE Co., LTD
              </Text>
            </Flex>
          </Box>

          <SimpleGrid
            spacingY="10px"
            columns={{ base: 1, sm: 2, md: 2, lg: 2, xl: 2 }}
            pt={{
              base: "10px",
              sm: "10px",
              md: "10px",
              lg: "0px",
              xl: "0px",
            }}
          >
            <Box
              w="100%"
              fontSize={{
                base: "xs",
                sm: "sm",
                md: "sm",
                lg: "md",
                xl: "md",
              }}
            >
              <Text fontWeight="bold" pb="10px">
                Company
              </Text>
              <Link to="about">
                <Text cursor="pointer" pb="10px">
                  About
                </Text>
              </Link>

              <Link to="contact">
                <Text cursor="pointer">Contact</Text>
              </Link>
            </Box>

            <Box
              w="100%"
              fontSize={{
                base: "xs",
                sm: "sm",
                md: "sm",
                lg: "md",
                xl: "md",
              }}
            >
              <Text fontWeight="bold" pb="10px">
                Get in Touch
              </Text>

              <Text pb="10px">
                <Icon
                  w="20px"
                  h="20px"
                  color="red.600"
                  mr="15px"
                  as={BsFillTelephoneFill}
                />
                09 779 204 629
              </Text>

              <Text>
                <Icon
                  w="22px"
                  h="22px"
                  color="red.600"
                  mr="15px"
                  as={MdEmail}
                />
                mtdbearing@ytp.com.mm
              </Text>
            </Box>
          </SimpleGrid>
        </SimpleGrid>

        <Spacer />

        <SimpleGrid
          pt="10px"
          spacing="10px"
          columns={{ base: 1, sm: 1, md: 1, lg: 2, xl: 2 }}
          fontSize={{
            base: "xs",
            sm: "sm",
            md: "sm",
            lg: "md",
            xl: "md",
          }}
        >
          <Flex
            flexDirection={{
              base: "column",
              sm: "column",
              md: "column",
              lg: "row",
              xl: "row",
            }}
          >
            <Text>
              <Icon
                w="22px"
                h="22px"
                color="red.600"
                mr="15px"
                as={FaFacebook}
              />
              @MTE.COM.MM
            </Text>
            <Text
              onClick={() => window.open("https://mtdbearing.com/", "_blank")}
              cursor="pointer"
              ml={{
                base: "0px",
                sm: "0px",
                md: "0px",
                lg: "15px",
                xl: "15px",
              }}
              mt={{
                base: "10px",
                sm: "10px",
                md: "10px",
                lg: "0px",
                xl: "0px",
              }}
            >
              <Icon
                w="22px"
                h="22px"
                color="red.600"
                mr="15px"
                as={RiGlobalFill}
              />
              www.mtdbearing.com
            </Text>
          </Flex>
          <Text
            textAlign={{
              base: "left",
              sm: "left",
              md: "left",
              lg: "right",
              xl: "right",
            }}
          >
            <Icon
              w="22px"
              h="22px"
              color="red.600"
              mr="15px"
              as={AiOutlineCopyrightCircle}
            />
            Myat Thida Trading Enterprise Co., LTD
          </Text>
        </SimpleGrid>
      </Flex>
    </>
  );
};

export default Footer;
